import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@/components/ui/accordion";

export default function AccordionFAQ() {
  return (
    <Accordion className="w-full" type="multiple">
      <AccordionItem value="item-1">
        <AccordionTrigger className="text-base sm:text-lg">
          Apa itu Ekonomi.FYI?
        </AccordionTrigger>
        <AccordionContent className="text-base">
          Ekonomi.FYI adalah sebuah platform untuk memperkenalkan ilmu ekonomi
          pada khalayak umum secara gratis
        </AccordionContent>
      </AccordionItem>
      <AccordionItem value="item-2">
        <AccordionTrigger className="text-base sm:text-lg">
          Apa saja yang disediakan Ekonomi.FYI?
        </AccordionTrigger>
        <AccordionContent className="text-base ">
          Kami memiliki media sosial yang menyajikan berbagai konten-konten
          terkait dengan ilmu ekonomi beserta website yang dapat digunakan untuk
          belajar dan berlatih kemampuan siswa dalam ilmu ekonomi
        </AccordionContent>
      </AccordionItem>
      <AccordionItem value="item-3">
        <AccordionTrigger className="text-base sm:text-lg">
          Apakah terdapat biaya berlangganan?
        </AccordionTrigger>
        <AccordionContent className="text-base">
          Tidak, kami menyediakan Ekonomi.FYI secara gratis
        </AccordionContent>
      </AccordionItem>
    </Accordion>
  );
}
